import { AnonymousGuard } from './services/anonymous-guard.service';
import { AuthGuard } from './services/auth.guard.service';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule), canActivate: [AuthGuard] },
  { path: 'gate-keepers', loadChildren: () => import('./pages/gate-keepers/gate-keepers.module').then(m => m.GateKeepersModule), canActivate: [AuthGuard] },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule), canActivate: [AnonymousGuard] },
  { path: 'entertainment-centers', loadChildren: () => import('./pages/entertainment-centers/entertainment-centers.module').then(m => m.EntertainmentCentersModule), canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
