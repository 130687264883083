import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  jwtHelper: JwtHelperService = new JwtHelperService();

  constructor(
    private httpClient: HttpClient,
  ) { }

  login(userData: {}) {
    return this.httpClient.post(`${environment.apiURL}api/organizer/user/login`, userData)
  }
  viewOrganizer() {
    return this.httpClient.get(`${environment.apiURL}api/organizer/user/view`)
  }
  setPassword(verifyData: {}) {
    return this.httpClient.post(`${environment.apiURL}api/organizer/tickets-providers/verify`, verifyData)
  }

  public storeToken({ token }: { token: string }) {
    localStorage.setItem('token', token)
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token')
    if (!token) {
      return false;
    }

    const { roles } = this.jwtHelper.decodeToken(token);
    if (!roles || (roles.length && !roles.includes('organizer'))) {
      return false;
    }
    
    if (this.jwtHelper.isTokenExpired(token)) {
      return false;
    }
    return true;
  }

  public getToken(): string {
    const token = localStorage.getItem('token') || '';
    return token
  }
}
