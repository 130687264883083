<nz-layout *ngIf="isAuthenticated" dir="rtl" class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="/" target="_blank">
        <img src="/assets/tktahlogo.svg" alt="logo" />
      </a>
    </div>
    <ul class="menu-list" nz-menu nzTheme="light" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item [routerLink]="['/dashboard']" [nzSelected]="isSelected('/dashboard')">
           <img
           src="assets/images/home.svg"
           width="18px"
           height="18px"
           style="margin-left: 12px;"/>
        <span>لوحة التحكم</span>
      </li>
      <li nz-menu-item [routerLink]="['/gate-keepers']" [nzSelected]="isSelected('/gate-keepers')">
        <span nz-icon nzType="usergroup-delete" nzTheme="outline"></span>
        <span>منظمي الدخول</span>
      </li>
      <li nz-menu-item [routerLink]="['/reports']" [nzSelected]="isSelected('/reports')">
         <img
           src="assets/images/reports.svg"
           width="18px"
           height="18px"
           style="margin-left: 12px;"/>
        <span>التقارير</span>
      </li>
      <li nz-menu-item (click)="showModal()">
        <span nz-icon nzType="logout" nzTheme="outline"></span>
        <span>تسجيل الخروج</span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger">
          <span (click)="isCollapsed = !isCollapsed" class="trigger" nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
          <div class="user-info">
            <img class="profile-icon" src="/assets/images/profile-icon.svg" alt="profile-icon" />
            <div class="user-name">{{organizer?.firstName + ' ' + organizer?.lastName}}</div>
            <img (click)="showModal()" class="logout-icon" src="/assets/images/logout.svg" alt="logout" />
          </div>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>

<nz-layout *ngIf="!isAuthenticated">
  <router-outlet></router-outlet>
</nz-layout>

<nz-modal [(nzVisible)]="isVisible" [nzContent]="modalContent" [nzFooter]="modalFooter" [nzClosable]="false">
  <ng-template #modalContent>
    <p nzJustify="center">هل انت متاكد من تسجيل الخروج؟</p>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button class="custom-btn-for-cancel" (click)="handleCancel()">
      الغاء
    </button>
    <button nz-button class="custom-btn" (click)="logout()">تسجيل خروج</button>
  </ng-template>
</nz-modal>