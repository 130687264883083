import { Component, OnInit } from '@angular/core';
import { AuthService } from "./services/auth.service";
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCollapsed = false;
  isAuthenticated: boolean;
  isVisible = false;
  organizer: any;

  constructor(
    private authService: AuthService,
    private msg: NzMessageService,
    private router: Router,
    private modal: NzModalService,
  ) {
    this.isAuthenticated = this.authService.isAuthenticated()
  }
  ngOnInit(): void {
    this.getOrganizer();
  }
  getOrganizer() {
    this.authService.viewOrganizer().subscribe(
      (response: any) => {
        this.organizer = response.user;
      }
    );
  }
  isSelected(route: string): boolean {
    return route === this.router.url;
  }

  showModal(): void {
    this.isVisible = true;
  }

  logout(): void {
    localStorage.removeItem('token');
    this.msg.success('تم تسجيل الخروج بنجاح');
    window.location.reload();
    this.isVisible = false;
  }

  handleCancel(): void {
    this.msg.info("تم الغاء تسجيل الخروج")
    this.isVisible = false;
  }
}
